// Libraries
import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
// Routes
import ForgetPassword from "../User/ForgetPass.js";
const Home = lazy(() => import("../Website/Home/Home"));
const VerifyNow = lazy(() => import("../Website/VerifyNow.js"));
/* User Form Routes */
const RefundPolicy = lazy(() => import("../Website/RefundPolicy.js"));
const Contact = lazy(() => import("../Website/Contact.js"));
// Packages
const ReportPackages = lazy(() => import("../Website/ReportPackages.js"));
const ReportPlusPackages = lazy(() => import("../Website/ReportPlusPackages.js"));
const TranslatePackages = lazy(() => import("../Website/TranslatePackages.js"));
const ExportPackages = lazy(() => import("../Website/ExportPackages.js"));
// Payments
const Buy = lazy(() => import("../Website/Buy.js"));
const ReportPlusBuy = lazy(() => import("../Website/ReportPlusBuy.js"));
const TranslateBuy = lazy(() => import("../Website/TranslateBuy.js"));
const ExportCertBuy = lazy(() => import("../Website/ExportCertBuy.js"));
const Pay = lazy(() => import("../User/Pay.js"));
const Translate = lazy(() => import("../Website/Translate.js"));
const ReportPlus = lazy(() => import("../Website/ReportPlus.js"));
const ReportPlusOne = lazy(() => import("../Website/ReportPlus-One.js"));
const ExportCert = lazy(() => import("../Website/ExportCert.js"));
// User Import
const SignIn = lazy(() => import("../User/SignIn.js"));
const SignUp = lazy(() => import("../User/SignUp.js"));
const Profile = lazy(() => import("../User/Profile.js"));
// AuctionSheet
const AuctionSheet = lazy(() => import("../Website/AuctionSheet.js"));
const SavedAuctionSheet = lazy(() => import("../Website/SavedAuctionSheet.js"));
// Newsletter
const Unsubscribe = lazy(() => import("../Website/Unsubscribe.js"));
// Layout
const WebsiteLayout = lazy(() => import("../Layouts/Website.layout.js"));
// Error Page
const ThankYou = lazy(() => import("../Website/ThankYou.js"));
const ReportPlusThankYou = lazy(() => import("../Website/ReportPlusThankYou.js"));
const TranslateThankYou = lazy(() => import("../Website/TranslateThankYou.js"));
const ExportThankYou = lazy(() => import("../Website/ExportThankYou.js"));
const TranslateSuccess = lazy(() => import("../Website/TranslateSuccess.js"));
const ReportPlusSuccess = lazy(() => import("../Website/ReportPlusSuccess.js"));
const ExportCertSuccess = lazy(() => import("../Website/ExportCertSuccess.js"));
const Success = lazy(() => import("../Website/Success.js"));
/* Cart Routes */
const FindAuctionSheet = lazy(() => import("../Website/Cart/FindAuctionSheet.js"));
const Cart = lazy(() => import("../Website/Cart/Cart.js"));
const Checkout = lazy(() => import("../Website/Cart/Checkout.js"));
const PaymentMethod = lazy(() => import("../Website/StaticPages/PaymentMethod.js"));
// static pages
const Faqs = lazy(() => import("../Website/StaticPages/Faqs.js"));
const About = lazy(() => import("../Website/StaticPages/About.js"));
const AdvertiseWithUs = lazy(() => import("../Website/StaticPages/AdvertiseWithUs.js"));
const CommentPolicy = lazy(() => import("../Website/StaticPages/CommentPolicy.js"));
const DMCA = lazy(() => import("../Website/StaticPages/DMCA.js"));
const HowPointsWork = lazy(() => import("../Website/StaticPages/HowPointsWork.js"));
const HowItWorks = lazy(() => import("../Website/StaticPages/HowItWorks.js"));
const HowToRead = lazy(() => import("../Website/StaticPages/HowToRead.js"));
const OurAuthors = lazy(() => import("../Website/StaticPages/OurAuthors.js"));
const OurHistory = lazy(() => import("../Website/StaticPages/OurHistory.js"));
const PrivacyPolicy = lazy(() => import("../Website/StaticPages/PrivacyPolicy.js"));
const WriteForUs = lazy(() => import("../Website/StaticPages/WriteForUs.js"));
const Feedback = lazy(() => import("../Website/StaticPages/Feedback.js"));
const Videos = lazy(() => import("../Website/StaticPages/Videos.js"));
/* Ultra Autonomous Page */
const CustomPage = lazy(() => import("../Website/CustomPage/CustomPage"));

export default function WebsiteRoutes() {
  return (
    <Switch>
      <WebsiteLayout exact path="/" render={(props) => <Home />} />
      <WebsiteLayout exact path="/verify-now" render={(props) => <VerifyNow />} />
      {/* User Form Routes */}
      <WebsiteLayout exact path="/refund-policy" render={(props) => <RefundPolicy />} />
      <WebsiteLayout exact path="/contact" render={(props) => <Contact />} />
      {/* Auth Routes */}
      <WebsiteLayout exact path="/sign-in" render={(props) => <SignIn />} />
      <WebsiteLayout exact path="/sign-up" render={(props) => <SignUp />} />
      <WebsiteLayout exact path="/profile" render={(props) => <Profile />} />
      <WebsiteLayout exact path="/forget-password" render={(props) => <ForgetPassword />} />
      {/* Newsletter */}
      <WebsiteLayout exact path="/unsubscribe" render={(props) => <Unsubscribe />} />
      {/* Translate to English */}
      <WebsiteLayout exact path="/packages/translate-to-english" render={(props) => <TranslatePackages />} />
      <WebsiteLayout exact path="/buy/translate/:packages" render={(props) => <TranslateBuy />} />
      <WebsiteLayout exact path="/translate-to-english" render={(props) => <Translate />} />
      <WebsiteLayout exact path="/translate-to-english/success" render={(props) => <TranslateSuccess />} />
      <WebsiteLayout exact path="/translate-to-english/success/:id" render={(props) => <TranslateSuccess />} />
      <WebsiteLayout exact path="/thank-you/translate/:success/:t/:b" render={(props) => <TranslateThankYou />} />
      <WebsiteLayout exact path="/thank-you/translate" render={(props) => <TranslateThankYou />} />
      {/* Report Plus */}
      <WebsiteLayout exact path="/packages/report+" render={(props) => <ReportPlusPackages />} />
      <WebsiteLayout exact path="/buy/report-plus/:packages" render={(props) => <ReportPlusBuy />} />
      <WebsiteLayout exact path="/report+" render={(props) => <ReportPlusOne />} />
      <WebsiteLayout exact path="/report+/success" render={(props) => <ReportPlusSuccess />} />
      <WebsiteLayout exact path="/report+/:chassis" render={(props) => <ReportPlus />} />
      <WebsiteLayout exact path="/report+/success/:id" render={(props) => <ReportPlusSuccess />} />
      <WebsiteLayout exact path="/thank-you/report-plus/:success/:t/:b" render={(props) => <ReportPlusThankYou />} />
      <WebsiteLayout exact path="/thank-you/report-plus" render={(props) => <ReportPlusThankYou />} />
      {/* Export Certificate */}
      <WebsiteLayout exact path="/packages/export-certifcate-translate" render={(props) => <ExportPackages />} />
      <WebsiteLayout exact path="/buy/export-cert/:packages" render={(props) => <ExportCertBuy />} />
      <WebsiteLayout exact path="/export-certificate-translation" render={(props) => <ExportCert />} />
      <WebsiteLayout exact path="/export-certificate-translation/success" render={(props) => <ExportCertSuccess />} />
      <WebsiteLayout exact path="/export-certificate-translation/success/:id" render={(props) => <ExportCertSuccess />} />
      <WebsiteLayout exact path="/thank-you/export-cert/:success/:t/:b" render={(props) => <ExportThankYou />} />
      <WebsiteLayout exact path="/thank-you/export-cert" render={(props) => <ExportThankYou />} />
      {/* True Report */}
      <WebsiteLayout exact path="/packages/report" render={(props) => <ReportPackages />} />
      <WebsiteLayout exact path="/buy/:packages" render={(props) => <Buy />} />
      <WebsiteLayout exact path="/thank-you/:success/:t/:b" render={(props) => <ThankYou />} />
      <WebsiteLayout exact path="/thank-you" render={(props) => <ThankYou />} />
      <WebsiteLayout exact path="/auctionsheet/:id" render={(props) => <AuctionSheet />} />
      <WebsiteLayout exact path="/success/:id" render={(props) => <Success />} />
      {/* True Report Cart Routes */}
      <WebsiteLayout exact path="/find-auction-sheet/:chassis" render={(props) => <FindAuctionSheet />} />
      <WebsiteLayout exact path="/cart" component={Cart} />
      <WebsiteLayout exact path="/checkout" component={Checkout} />
      <WebsiteLayout exact path="/payment-method" render={(props) => <PaymentMethod />} />
      {/* static pages */}
      <WebsiteLayout exact path="/about-us" render={(props) => <About />} />
      <WebsiteLayout exact path="/videos" render={(props) => <Videos />} />
      <WebsiteLayout exact path="/advertise-with-us" render={(props) => <AdvertiseWithUs />} />
      <WebsiteLayout exact path="/about-us" render={(props) => <About />} />
      <WebsiteLayout exact path="/advertise-with-us" render={(props) => <AdvertiseWithUs />} />
      <WebsiteLayout exact path="/comment-policy" render={(props) => <CommentPolicy />} />
      <WebsiteLayout exact path="/dmca" render={(props) => <DMCA />} />
      <WebsiteLayout exact path="/how-points-work" render={(props) => <HowPointsWork />} />
      <WebsiteLayout exact path="/how-it-works" render={(props) => <HowItWorks />} />
      <WebsiteLayout exact path="/how-to-read" render={(props) => <HowToRead />} />
      <WebsiteLayout exact path="/our-authors" render={(props) => <OurAuthors />} />
      <WebsiteLayout exact path="/our-history" render={(props) => <OurHistory />} />
      <WebsiteLayout exact path="/privacy-policy" render={(props) => <PrivacyPolicy />} />
      <WebsiteLayout exact path="/write-for-us" render={(props) => <WriteForUs />} />
      <WebsiteLayout exact path="/feedback" render={(props) => <Feedback />} />
      <WebsiteLayout exact path="/faqs" render={(props) => <Faqs />} />
      {/* Sheets Route */}
      <WebsiteLayout exact path="/saved-reports/:id" render={(props) => <SavedAuctionSheet />} />
      {/* Ultra Autonomous Route */}
      <WebsiteLayout exact path="/:url" render={(props) => <CustomPage />} />
    </Switch>
  );
}
