import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function SignIn() {

  return (
    <>
      <Container>
        <div className="text-center mt-5">
          <h1 className="text-dark font-weight-bold">Reset your password</h1>
          <p>Enter your email to reset password</p>
        </div>
        <div className="">
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                  <Form.Group controlId="formBasicEmail" className="border-bottom">
                    <Form.Control type="email" className="border-0 mt-5 w-100"
                        name="email"
                        placeholder="Enter email"
                        required
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    className="btn-block rounded-0 mt-4"
                    style={{backgrounColor:'#1f62f2!important'}}
                  >
                    Reset your password
                  </Button>
                  <div className="d-flex mt-3">
                    <p className="pr-2">Don't have an accont? </p> <Link to="/sign-up">SignUp</Link>
                  </div>
              </Col>
              <Col md={4}></Col>
            </Row>
            
        </div>
      </Container>
    </>
  ) 
}

export default SignIn;
