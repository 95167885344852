// Libraries
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop.js";
// Routes
import WebsiteRoutes from './Routes/Website.routes.js';
// Provider
import { UserProvider } from './Context/User.context.js';
// App CSS
import './App.css';
import { Spinner } from 'react-bootstrap'

const Loader = () => {
  return (
    <div className="d-flex justify-content-center my-5">
      <Spinner animation="grow" variant="primary" />
    </div>
  )
}

function App() {
  return (
    <UserProvider>
      <Suspense fallback={<Loader/>}>
        <Router>
          <ScrollToTop />
          <WebsiteRoutes />
        </Router>
      </Suspense>
    </UserProvider>
  )
}

export default App;
