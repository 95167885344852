import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import SimpleReactLightbox from 'simple-react-lightbox'
// CSS
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <SimpleReactLightbox>
    <App />
  </SimpleReactLightbox>,
  document.getElementById('root')
);
